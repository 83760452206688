import React, {useEffect, useState} from 'react';
import '../css/mcFormStyles.css';

const InputField = props => {
  
  const [status, setStatus] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  useEffect(()=>{

    
//this following line of code is brittle? used logical left hand short-circuit to
//make this work, but react is not receiving a default value for formValues, causing 
//crash on modal page when this is called. Couldnt figure another way to pass value in

//for some reasone every render hits both of these conditions
// MAYBE THIS IS WHY WE GET BOTH CONDITIONS AS TRUE

//adding dependency check to render on changes to 'props.formValues' fixed above issue
    if ((props.formValues && props.formValues.some(f => f === "")) || (props.formValues && props.formValues[0].indexOf("@") === -1) || props.checkboxStatus === false) {
       setDisableButton(true)
    } 
    else {
      setDisableButton(false)
    }

  },[props.formValues, props.checkboxStatus])

  const validateInput = () => {
    
        //conditions that will invalidate form
        // if (values.some(f => f === "") || values[0].indexOf("@") === -1 /*|| status === false*/) {
          if (props.formValues.some(f => f === "") || props.formValues[0].indexOf("@") === -1 /*|| status === false*/) {
            
          // setDisableButton(true)
          return true
        } 
        else {
            return false
        }
    }

    if (props.type === "submit") {
        return (
            <input
                className='mc__submitbutton'
                type='submit'
                value={props.label}
                disabled={disableButton /*(validateInput(props.formValues, status))*/}
            />
        )
    } else if (props.type === "textarea") {
        return (
           <label className="mc__title">
                {props.label}
                <textarea
                    onChange={(e) => props.onChangeHandler(e.target.value)}
                    placeholder={props.placeholder}
                    value={props.value}
                    required={props.isRequired}
                    className="mc__inputfield"
                    rows={7}
                    name={props.name}
                />
            </label>
        );
    } 
    else if (props.type === "checkbox") {
      return (
         <label className="mc_consent" onChange={(e) => props.onChangeHandler(e.target.checked)}>
              <input type="checkbox" id="consent_box" name="consent_box" style={{marginRight: "10px", width: "12px", height: "15px"}}/>
              If I am selected as a winner, I consent to my audio recording being included on a video posted on Beepboop’s social media accounts.              
          </label>
      );
    } 
    else {
        return (
            <label className="mc__title">
                {props.label}
                <input
                    onChange={(e) => props.onChangeHandler(e.target.value)}
                    type={props.type}
                    placeholder={props.placeholder}
                    value={props.value}
                    required={props.isRequired}
                    className="mc__inputfield"
                    name={props.name}
                />
            </label>
        );
    }


};

export default React.memo(InputField);
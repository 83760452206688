import React,  { Component, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import firebase from "firebase";
import 'firebase/analytics';

import 'bootstrap/dist/css/bootstrap.min.css';


import { AudioComponent } from "./pages/Audio.js";

const firebaseConfig = {
  apiKey: "AIzaSyCym9OZg2XzQrNJO1AYfLFIU1IR8hgLp3Y",
  authDomain: "dev-divine-beepboop.firebaseapp.com",
  projectId: "dev-divine-beepboop",
  storageBucket: "dev-divine-beepboop.appspot.com",
  messagingSenderId: "884480296630",
  appId: "1:884480296630:web:f61ddddc09982f74c98817",
  measurementId: "G-PQ5ZNE96V5"
};
// Initialize Firebase
//firebase.initializeApp(firebaseConfig); //on refresh would get 'Firebase App named '[DEFAULT]' already exists'

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}else {
  firebase.app(); // if already initialized, use that one
}

const logCurrentPage = () => {
  firebase.analytics().setCurrentScreen(window.location.pathname)
  firebase.analytics().logEvent('screen_view')
};

const AnalyticsComponent = () => {
  const history = useHistory();
  useEffect(() => {
    logCurrentPage(); // log the first page visit
    history.listen(() => {
      logCurrentPage();
    });
  }, [history]);
  return (<div></div>);
};

class App extends Component {
  constructor(props) { 
    super(props);
    this.state = { 

    };
  }

  
  render(){
    return (
    <div>
      <Router>
        <AnalyticsComponent />
        <Switch>
          <Route exact path="/" component={AudioComponent} />
          <Route exact path="*" component={AudioComponent} />
        </Switch>
      </Router>
    </div>
    );
  }
}

export default App;

import React, { useState, useEffect } from "react";
import {  Button, Modal } from "react-bootstrap";


import "../css/audio.css";
import logo from "../assets/ratemylogobadbunny.png";
import brand from "../assets/beepboop_small.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMousePointer, faMicrophone, faPaperPlane, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import quokka from "../assets/hand_talkka.png"
import '../css/mcFormStyles.css';

import firebase from "firebase";
import 'firebase/analytics';

import MailchimpFormContainer from "../components/MailchimpForm"
import { AnimatedTitle } from "../components/AnimatedTitle";


const APIRootUrl = "https://us-central1-dev-divine-beepboop.cloudfunctions.net/app"
//const APIRootUrl = "http://localhost:5001/dev-divine-beepboop/us-central1/app"
const MicRecorder = require("mic-recorder-to-mp3");
// New instance
const recorder = new MicRecorder({
  bitRate: 128,
});


export const AudioComponent = () => { 

  const [show, setShow] = useState(false);
  const [stateAudioFile, setStateAudioFile] = useState(null);
  const [beganRecording, setBeganRecording] = useState(false);
  const [stoppedRecording, setStoppedRecording] = useState(false);

  const handleClose = () => {
    setStateAudioFile(null);
    setBeganRecording(false);
    setStoppedRecording(false);
    updateTextIndex(0);
    setShow(false);
  }
  const handleShow = () => setShow(true);

  // You can toggle this with intersection observer.
  const [inView, updateInView] = useState(false);
  const [textIndex, updateTextIndex] = useState(0);
  const [chosenDynamicText, updateChosenDynamicText] = useState("")
  const [textArray, updateTextArray] = useState([
    {text:"Activate Microphone", duration:3000},
    {bannerText: `Read Spanish Out Loud Passionately \n Level: Easy`,text:"", duration:1000},
    {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 3...`,text:"Perro", translatedText: "Dog", duration:1000},
    {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 2...`,text:"Perro", translatedText: "Dog", duration:1000},
    {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 1...`,text:"Perro", translatedText: "Dog", duration:1000},
    
    {bannerText: `Read Spanish Out Loud Passionately \n Level: Medium`,text:"", duration:1000},
    {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 5...`,text:"Perro", translatedText: "Dog", duration:1000},
    {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 4...`,text:"Perro", translatedText: "Dog", duration:1000},
    {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 3...`,text:"Perro", translatedText: "Dog", duration:1000},
    {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 2...`,text:"Perro", translatedText: "Dog", duration:1000},
    {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 1...`,text:"Perro", translatedText: "Dog", duration:1000},

    {bannerText: `Read Spanish Out Loud Passionately \n Level: Hard`,text:"", duration:1000},
    {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 8...`,text:"Perro", translatedText: "Dog", duration:1000},
    {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 7...`,text:"Perro", translatedText: "Dog", duration:1000},
    {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 6...`,text:"Perro", translatedText: "Dog", duration:1000},
    {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 5...`,text:"Perro", translatedText: "Dog", duration:1000},
    {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 4...`,text:"Perro", translatedText: "Dog", duration:1000},
    {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 3...`,text:"Perro", translatedText: "Dog", duration:1000},
    {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 2...`,text:"Perro", translatedText: "Dog", duration:1000},
    {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 1...`,text:"Perro", translatedText: "Dog", duration:1000},
  ]);
  
  useEffect(()=>{
    updateTextArray([
      {text:"Activate Microphone", duration:4000},
      {bannerText: `Read Spanish Out Loud Passionately \n Level: Easy - 3 Seconds`,text:"", duration:2000},
      {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 3...`,text: chosenDynamicText.chosenEasyText, translatedText: chosenDynamicText.chosenEasyTranslatedText, duration:1000},
      {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 2...`,text: chosenDynamicText.chosenEasyText, translatedText: chosenDynamicText.chosenEasyTranslatedText, duration:1000},
      {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 1...`,text: chosenDynamicText.chosenEasyText, translatedText: chosenDynamicText.chosenEasyTranslatedText, duration:1000},
      
      {bannerText: `Read Spanish Out Loud Passionately \n Level: Medium - 5 Seconds`,text:"", duration:2000},
      {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 5...`,text:chosenDynamicText.chosenMediumText, translatedText: chosenDynamicText.chosenMediumTranslatedText, duration:1000},
      {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 4...`,text:chosenDynamicText.chosenMediumText, translatedText: chosenDynamicText.chosenMediumTranslatedText, duration:1000},
      {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 3...`,text:chosenDynamicText.chosenMediumText, translatedText: chosenDynamicText.chosenMediumTranslatedText, duration:1000},
      {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 2...`,text:chosenDynamicText.chosenMediumText, translatedText: chosenDynamicText.chosenMediumTranslatedText, duration:1000},
      {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 1...`,text:chosenDynamicText.chosenMediumText, translatedText: chosenDynamicText.chosenMediumTranslatedText, duration:1000},

      {bannerText: `Read Spanish Out Loud Passionately \n Level: Hard - 8 Seconds`,text:"", duration:2000},
      {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 8...`,text:chosenDynamicText.chosenHardText, translatedText: chosenDynamicText.chosenHardTranslatedText, duration:1000},
      {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 7...`,text:chosenDynamicText.chosenHardText, translatedText: chosenDynamicText.chosenHardTranslatedText, duration:1000},
      {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 6...`,text:chosenDynamicText.chosenHardText, translatedText: chosenDynamicText.chosenHardTranslatedText, duration:1000},
      {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 5...`,text:chosenDynamicText.chosenHardText, translatedText: chosenDynamicText.chosenHardTranslatedText, duration:1000},
      {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 4...`,text:chosenDynamicText.chosenHardText, translatedText: chosenDynamicText.chosenHardTranslatedText, duration:1000},
      {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 3...`,text:chosenDynamicText.chosenHardText, translatedText: chosenDynamicText.chosenHardTranslatedText, duration:1000},
      {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 2...`,text:chosenDynamicText.chosenHardText, translatedText: chosenDynamicText.chosenHardTranslatedText, duration:1000},
      {bannerText: `Read Spanish Out Loud Passionately \n Time Remaining: 1...`,text:chosenDynamicText.chosenHardText, translatedText: chosenDynamicText.chosenHardTranslatedText, duration:1000},
    ])
  },[chosenDynamicText] )

  function handleStartRecording(event) { 
    event.preventDefault();
    firebase.analytics().logEvent('start_recording')

    setBeganRecording(true);
    // Start recording. Browser will request permission to use your microphone.
    recorder.start().then(() => {
      // something else
    }).catch((e) => {
      //console.error(e);
    });
  }

  function submitRecording(file, formData) {
    firebase.analytics().logEvent('submit_recording')

    //returns out of function if file size larger than 5MB
    if (file.size > 5 * 1024 * 1024) {
      return console.log ("File too large")
    }

      fetch(`${APIRootUrl}/api/divine_audio_record2`, {
        method: "POST",
        headers: {
          //Accept: "application/json",
          // "Content-type": "application/json",
          // "Content-type": "text/javascript",
          "visitorfirstname" : formData.MERGE1,
          "visitoremail" : formData.EMAIL,
        },
        body: file,
        
      }).then((res)=>{     
        //console.log("RESPONSE:", res);
      });
  }

  function handleStopRecording(event) { 
    firebase.analytics().logEvent('stop_recording')

    handleShow()
    if (event) {event.preventDefault()}
    
    // Once you are done singing your best song, stop and get the mp3.
    recorder
      .stop()
      .getMp3().then(([buffer, blob]) => {
      // do what ever you want with buffer and blob
      // Example: Create a mp3 file and play
        const file = new File(buffer, "me-at-thevoice.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });
    
        // MCD: disabling playback for now
        //const player = new Audio(URL.createObjectURL(file));
        //player.play();
        setStateAudioFile(file)
        
        return file;
      })
  }

    // Don't do this in production :)
    useEffect(() => {
      if(beganRecording){
        updateInView(true);

        let timeout = setTimeout(() => {
          updateInView(!inView);

          if (textIndex < (textArray.length-1)) {
            updateTextIndex(textIndex + 1);
          }

          if (textIndex === (textArray.length-1) && stoppedRecording === false) {
            setStoppedRecording(true);
            handleStopRecording();
          }

        }, textArray[textIndex].duration);
    
        return () => clearTimeout(timeout);
      }

    }, [beganRecording, inView, textIndex, textArray]);

    // }, [inView]);
    useEffect(()=>{

      const fullDynamicTextArray= {
        easyText:["Perrear", "Espantar", "Chingar", "Un rato", "Enterarte", "Coger"],
        easyTranslatedText:["To twerk", "To scare", "To mess around / To steal / To bother / To Fuck", "A moment", "To find out", "To take / To fuck"],

        mediumText:["Perrear toda la noche","Espantaré a esos bobos", "Ella puede ver", "Nos queda un rato", "Te vas a enterar", "Coger un vuelo"], 
        mediumTranslatedText:["To twerk all night long","I will scare those idiots", "She can see", "We have a moment", "You are going to find out", "To take a flight"], 

        hardText:["El perreo es su profesión", "A estos bobos con la forty los espanto", "Tú te ve' asesina con ese mahón", "Nos queda un ratito pa' formar el escándalo", "Ya yo me enteré, se nota cuando me ve'", "Dime dónde tú está', que yo por ti cojo un vuelo" ],
        hardTranslatedText: ["Twerking is her profession", "I can scare off these idiots with their 40 oz. beers", "You look killer in those jeans", "We just have a short time to get crazy", "I already found out, it shows when you look at me", "Tell me where you are, for you I can take a flight" ]

      }

     function dynamicTextChooser() {
      let easyIndex = Math.floor(Math.random() * fullDynamicTextArray.easyText.length);
      let mediumIndex = Math.floor(Math.random() * fullDynamicTextArray.mediumText.length);
      let hardIndex = Math.floor(Math.random() * fullDynamicTextArray.hardText.length);

      return {
        chosenEasyText: fullDynamicTextArray.easyText[easyIndex],
        chosenEasyTranslatedText: fullDynamicTextArray.easyTranslatedText[easyIndex],
        chosenMediumText: fullDynamicTextArray.mediumText[mediumIndex],
        chosenMediumTranslatedText: fullDynamicTextArray.mediumTranslatedText[mediumIndex],
        chosenHardText: fullDynamicTextArray.hardText[hardIndex],
        chosenHardTranslatedText: fullDynamicTextArray.hardTranslatedText[hardIndex],
      }

     }
     updateChosenDynamicText(dynamicTextChooser())
     //console.log(dynamicTextChooser())
    }, [])

    
    function Translation() {
      if (textArray[textIndex].translatedText) {
        return (textArray[textIndex].translatedText)
      }
      return null;
    }

    function bannerText() {
      if (textArray[textIndex].bannerText) {
        return <div className="banner-container"> {textArray[textIndex].bannerText} </div>
      }
      return null;
    }

  return (
    <div className="page-container">
      <div className="background"/>
      {/* <img src={brand} alt="Brand" className="brand"/> */}
      <a href="https://www.beepboop.us" target="_blank" rel="noreferrer">
        <img src={logo} alt="Logo" className="logo" />
      </a>
      <div className="logo-text">
      How Bad Bunny is your Spanish? Let's hear it! <br/>Play below and win up to 12 months of 
      <a href="https://beepboop.us/pages/vip-spanish-classes" target="_blank" rel="noreferrer" style={{color: '#fdb869'}}> unlimited live Spanish lessons </a>
      from Beepboop
        <br></br>
        Hint: Just have fun. There are no wrong answers! 
      </div>
      {bannerText()}
      <div className="animation-container" style = {{display: beganRecording ? 'flex':'none'}}>
        <AnimatedTitle currentInView={inView}>
          {textArray[textIndex].text}
        </AnimatedTitle>
      </div>

{/* This invisible div exists as patchwork fix for bug where the translated line shows margin spacing properly on desktop emulator, but on actual mobile device margin does not exist and text overlaps into each other*/}
      {/* will research a more graceful way to fix this later */}
      <div className="animation-translation" style = {{display: beganRecording ? 'flex':'none', visibility: beganRecording ? 'hidden':'hidden'}}>{Translation()}</div>

      <div className="button-container"  style = {{display: beganRecording ? 'none':'flex'}}>
        <Button className="record-button" onClick={handleStartRecording}>START GAME</Button>
        {/* <Button className="record-button" style = {{visibility: beganRecording ? 'visible':'hidden'}} disabled = {!beganRecording} onClick={handleStopRecording}>Stop Recording</Button> */}
      </div>
      <div className="icons-container" style = {{display: beganRecording ? 'none':'flex'}}>
        <div className="icon-container">
          <FontAwesomeIcon icon= {faMousePointer} alt="pointer_icon" className="icon"/>
          <div className="icon-text">1. Hit "Start Game"</div>
        </div>
        <div className="icon-container">
          <FontAwesomeIcon icon={faMicrophone} alt="pointer_icon" className="icon"/>
          <div className="icon-text">2. Read, rap, or whatever the Bad Bunny lyrics</div>
        </div>
        <div className="icon-container">
          <FontAwesomeIcon icon={faPaperPlane} alt="pointer_icon" className="icon"/>
          <div className="icon-text">3. Submit & pray you get lucky </div>
        </div>
        <div className="icon-container">
          <FontAwesomeIcon icon={faFileAlt} alt="pointer_icon" className="icon"/>
          <div className="icon-text">4. Check email. Winners announced daily!</div>
        </div>
      </div>
      <div className="past-winner-text" style = {{display: beganRecording ? 'none':'block'}}>
      <a href="https://www.tiktok.com/@beepboop.us" target="_blank" rel="noreferrer">✨ Listen to previous winners! ✨</a>
      </div>

      <div className="animation-translation" style = {{display: beganRecording ? 'flex':'none'}}>{Translation()}</div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header className= "mc__header" style={{padding: "0"}}>
          <Modal.Title className="mc__title">
            <img src={quokka} alt="quokka" className="mc_quokka"/>
          </Modal.Title>
        </Modal.Header>
        {/* Q, is there a better way to structure this function to receive file data?
        callback func? can we pass dynamic props into it? should we pass this in
        state/props or as a normal variable since it doesnt affect display elements?*/}
        {/* <Modal.Body>{MailchimpFormContainer()}</Modal.Body> */}
        <Modal.Body><MailchimpFormContainer audioFile={stateAudioFile} submitRecording={submitRecording} handleClose={handleClose}/></Modal.Body>

      </Modal>

    </div>
  );
};

import React, {useState} from 'react';
import '../css/mcFormStyles.css';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import InputField from "./InputField";


const CustomForm = ({ status, message, onValidated, handleClose }) => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  // const [lastName, setLastName] = useState('');
  const [checkboxStatus, setCheckboxStatus] = useState(false);


  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
    firstName &&
    //lastName &&
    email.indexOf("@") > -1 &&
    onValidated({
        EMAIL: email,
        MERGE1: firstName,
        // MERGE2: lastName,
        MMERGE9: "RateMyRoll",
    });
}

  return (
<form className="mc__form" onSubmit={(e) => handleSubmit(e)}>
        <h3 className="mc__title">
          {(status === "success" || /\b(Click here to update your profile)\b/g.test(message))
              ? "Success!" 
              : "Please tell us how we can reach you if you win" 
            }
        </h3>

        {(status === "success" || /\b(Click here to update your profile)\b/g.test(message)) && (
          <div>
          <div  
            className="success-text"
            // dangerouslySetInnerHTML={{ __html: message }}
          >If you want more details or practice more pronunciation, book a free Beepboop online Spanish lesson. We have daily lessons every 30 mins!
          </div>
          <div className="success-button-container">
          <a className="success-button" target="_blank" rel="noreferrer noopener" href="https://beepboop.us/blogs/new-spanish-lessons/the-five-best-videos-to-learn-to-how-to-roll-your-rs">Learn to Roll your Rs</a>
          <a className="success-button" target="_blank" rel="noreferrer noopener" href="https://calendly.com/internal-monitor-drills?utm_source=website&utm_medium=ratemyroll&utm_campaign=ratemyrollbook">Book a Free Spanish Lesson</a>
          </div>
          </div>

        )}

        {status === "sending" && (
          <div className="mc__alert mc__alert--sending">
            sending...
          </div>
        )}
        {(status === "error" && !/\b(Click here to update your profile)\b/g.test(message) ) && (
          <div 
            className="mc__alert mc__alert--error"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}

      {(status === "success" || /\b(Click here to update your profile)\b/g.test(message)) ? null : (
        <div className="mc__field-container">
          <InputField
            label="First Name "
            onChangeHandler={setFirstName}
            type="text"
            value={firstName}
            placeholder="Your Name"
            isRequired
            checkboxStatus = {checkboxStatus}

          />

          <InputField
            label="Email "
            onChangeHandler={setEmail}
            type="email"
            value={email}
            placeholder="your@email.com"
            isRequired
            checkboxStatus = {checkboxStatus}

          />

          <InputField 
            label="consent"
            type="checkbox"
            // isRequired
            onChangeHandler={setCheckboxStatus}
            checkboxStatus = {checkboxStatus}
          />

        </div>
      )}


       
        {
          (status === 'success' || /\b(Click here to update your profile)\b/g.test(message))? /*<button
            onClick={() => handleClose(false)}
            className="mc__submitbutton">Close</button>*/null : <InputField
          label="Submit"
          type="submit"
          formValues={[email, firstName]}//, lastName]}
          checkboxStatus = {checkboxStatus}

        />
        }
      </form>

  );
};



const MailchimpFormContainer = props => {

  const uMailchimp = "dc3edc985866f39ca40ff17dd"
  const idMailchimp = "14c589d6df"
  const postUrl = `https://beepboop.us4.list-manage.com/subscribe/post?u=${uMailchimp}&id=${idMailchimp}`
  
    return (
        // <div className="mc__form-container">
        <div>
            <MailchimpSubscribe             
              url={postUrl}
              render={({ subscribe, status, message, setShow }) => (
                <CustomForm
                    status={status} 
                    message={message}
                    onValidated={async (formData) => {
                      subscribe(formData)
                      await props.submitRecording(props.audioFile, formData)
                    }}
                    handleClose={props.handleClose}
                />
            )}
            />
        </div>
    );
};

export default MailchimpFormContainer;
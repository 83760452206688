import React from "react";
import styled from '@emotion/styled';
// import { jsx } from '@emotion/react';

import { motion, AnimatePresence } from "framer-motion";
import { letterContainerVariants, letterVariants } from "./AnimationVariants";
import "../css/audio.css";


const StyledTitleElement = styled(motion.h2)`
  font-size: calc(20px + (80 - 32) * ((100vw - 320px) / (1600 - 320)));
  line-height: calc(20px + (80 - 32) * ((100vw - 320px) / (1600 - 320)));

  text-transform: uppercase;

  max-width: 100%;

  word-break: break-word;
  z-index: 10;

  color: white;
  
  position: absolute;
  text-align: center;
  font-family: "Montserrat";
  


`;
//  display: inline-block;
//  position: relative;
//  text-align: center;

// backgorund contrast
// background-color: #f4ac60;
// border-radius: 16px;
// padding-left: 20px;
// padding-right:20px;



export const AnimatedTitle = (props) => {
  const { children, currentInView } = props;

  return (
    <AnimatePresence>
      {currentInView && (
        <StyledTitleElement
          {...props}
          variants={letterContainerVariants}
          initial={"before"}
          animate={"after"}
          exit={"before"}
          key={children}
          aria-label={children}
          aria-live={"polite"} // dont do this on production if it loops.
        >
          {children.split(" ").map((word, wordI) => (
            <div
              key={`word-${word}-${wordI}`}
              style={{
                display: "inline-block"
                
              }}              
            >
              {Array.from(word).map((letter, index) => (
                <motion.span
                  key={`${index}-${letter}`}
                  style={{
                    position: "relative",
                    display: "inline-block",
                    width: "auto"
                  }} // Position elements
                  variants={letterVariants}
                >
                  {letter === " " ? "\u00A0" : letter}
                </motion.span>
              ))}
              {"\u00A0"}
            </div>
          ))}
        </StyledTitleElement>
      )}
    </AnimatePresence>
  );
};
